.footnotes {
  background-color: #fff;
  padding-block: var(--space-40);
}
@media (min-width: 768px) {
  .footnotes {
    padding-block: var(--space-80);
  }
}
.footnotes__header {
  margin-block-end: 1em;
}
.footnotes__note {
  color: var(--color-neutral-1100);
  font-size: 0.8rem;
  line-height: 1.15rem;
  letter-spacing: 0.3px;
}
.footnotes__note:not(:last-child) {
  margin-bottom: 0.5rem;
}